export default [
  {
    header: 'Menu',
    resource: 'dashboard',
    action: 'view',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'view',
  },
  {
    title: 'Data Hewan',
    route: 'goats',
    icon: 'GithubIcon',
    resource: 'animal',
    action: 'view',
  },
  {
    title: 'Susu Mentah',
    route: 'rawMilks',
    icon: 'DropletIcon',
    resource: 'raw_milk',
    action: 'view',
  },
  {
    title: 'Produk SmartFarm',
    route: 'milkProducts',
    icon: 'DropletIcon',
    resource: 'milk_product',
    action: 'view',
  },
  {
    title: 'Ternak',
    icon: 'MenuIcon',
    resource: 'animal_mating',
    action: 'view',
    children: [
      {
        title: 'List Perkawinan',
        route: 'matings',
        resource: 'animal_mating',
        action: 'view',
      },
      {
        title: 'Bunting',
        route: 'pregnant',
        resource: 'animal_mating',
        action: 'view',
      },
      {
        title: 'Beranak',
        route: 'giveBirth',
        resource: 'animal_mating',
        action: 'view',
      },
    ],
  },
  {
    header: 'Master Data',
    resource: 'master_milk_product',
    action: 'view',
  },
  {
    title: 'Ekspor Data',
    route: 'exports-page',
    icon: 'DownloadIcon',
    resource: 'data',
    action: 'export',
  },
  {
    title: 'Jenis Produk',
    route: 'master-product-categories',
    icon: 'MenuIcon',
    resource: 'master_milk_product',
    action: 'view',
  },
  {
    title: 'Kandang',
    route: 'masterPens',
    icon: 'MenuIcon',
    resource: 'master_milk_product',
    action: 'view',
  },
  {
    title: "Status Hewan",
    route: "master-animal-status",
    icon: "MenuIcon",
    resource: "master_milk_product",
    action: "view",
  },
  {
      title: "Status Keaktifan Hewan",
      route: "master-animal-status-outs",
      icon: "MenuIcon",
      resource: "master_milk_product",
      action: "view",
  },
  {
      title: "Device",
      route: "master-device",
      icon: "MenuIcon",
      resource: "master_milk_product",
      action: "view",
  },
  {
      title: "Users",
      route: "master-users",
      icon: "MenuIcon",
      resource: "master_milk_product",
      action: "view",
  },
  {
    header: 'Info Aplikasi',
    resource: 'master_milk_product',
    action: 'view',
  },
  {
    title: 'Lisensi Aplikasi',
    route: 'app-license',
    icon: 'InfoIcon',
    resource: 'master_milk_product',
    action: 'view',
  },
  {
    title: 'Perangkat IoT',
    route: 'devices-license',
    icon: 'CpuIcon',
    resource: 'master_milk_product',
    action: 'view',
  }, 
  
]
